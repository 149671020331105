import React from "react";
import { Spring } from "react-spring/renderprops";
import { Link } from "react-router-dom";

export const Studio = () => {
  return (
    <Spring
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
      config={{ delay: 400, duration: 1000 }}
    >
      {(props) => (
        <div style={props}>
          <div className="studio">
            <div className="landing-section">
              <div className="title-section">
                <div className="title">Relax. Recline.</div>
                <div className="sub-title">Wind Down.</div>
                <div className="studio-name">- Soul Sister Studio -</div>
              </div>
              <div className="image-section">
                <img
                  className="studio-landing-img"
                  src="/images/studio-landing.jpg"
                  alt="studio"
                />
              </div>
            </div>
            <div className="middle-section">
              <div className="image-section">
                <figure>
                  <div className="carousel dissolve">
                    <div className="items">
                      <div>
                        <img
                          className="studio-img"
                          src="/images/studio-pic2.jpg"
                          alt="studio"
                        />
                        <img
                          className="studio-img"
                          src="/images/studio-pic3.jpg"
                          alt="studio"
                        />
                        <img
                          className="studio-img"
                          src="/images/studio-pic4.jpg"
                          alt="studio"
                        />
                        <img
                          className="studio-img"
                          src="/images/studio-pic5.jpg"
                          alt="studio"
                        />
                      </div>
                    </div>
                  </div>
                </figure>
              </div>
              <div className="text-section">
                Soul Sister Studio is Alice Montana's personal studio based out
                of Carmel, IN. Alice’s goal is to educate clients on how to best
                take care of their skin along with helping them with the latest
                hair and makeup trends. The studio is all custom made/painted by
                Alice herself in order to provide a truly unique experience
                where everyone is welcome. You can even buy custom made Jewelry
                by Alice as well. Contact Alice to book an appointment today!
                <br />
                <br />
                <Link to="contact">
                  <button className="contact-btn">Contact</button>
                </Link>
              </div>
            </div>
            <div className="last-section">
              <div className="text-section">
                <i>
                  "Alice’s calm demeanor immediately puts clients at ease,
                  creating a relaxing and rejuvenating spa experience. In
                  addition, her expertise with chemical peels and dermaplaning
                  enhances clients’ facials and delivers results."
                </i>{" "}
                — Martha Tebbe
              </div>
              <div className="image-section">
                <img
                  className="studio-img"
                  src="/images/studio-pic1.jpg"
                  alt="studio"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Spring>
  );
};
