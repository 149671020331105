import { Route, Switch } from "react-router-dom";
import { Navbar } from './components/Navigation/Navbar';
import { FocusNavbar } from './components/Navigation/FocusNavbar';
import { Landing } from './components/Pages/Landing';
import { About } from './components/Pages/About';
import { Work } from './components/Pages/Work/Work';
import { AllWork } from './components/Pages/Work/AllWork';
import { Services } from './components/Pages/Services';
import { Testimonials } from './components/Pages/Testimonials';
import Contact from './components/Pages/Contact';
import { Studio } from './components/Pages/Studio';
import { Footer } from './components/Pages/Footer';

import './css/app.scss';

function App() {
  return (
    <div className="app">
       <Switch>
          <Route exact path="/">
            <Navbar />
            <Landing />
            <About />
            <Work />
            <Services />
            <Testimonials />
            <Footer />
          </Route>
          <Route exact path="/all-work">
            <FocusNavbar />
            <AllWork />
            <Footer />
          </Route>
          <Route exact path="/contact">
            <FocusNavbar />
            <Contact />
            <Footer />
          </Route>
          <Route exact path="/studio">
            <FocusNavbar />
            <Studio />
            <Footer />
          </Route>
        </Switch>
    </div>
  );
}

export default App;
