import React from "react";
import { Spring } from "react-spring/renderprops";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const Contact = () => {
  return (
    <Spring
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
      config={{ delay: 500, duration: 1000 }}
    >
      {(props) => (
        <div style={props}>
          <div className="contact-form-page">
            <div className="contact-information">
              <div className="contact-header">
                <div className="contact-info-message">Contact Information</div>
              </div>
              <div className="contact-body">
                <p className="contact-info-text">Book an appointment today!</p>
                <br />
                <p className="contact-info-option phone">
                  <FontAwesomeIcon icon={faPhone} size="m" /><a href="sms:3177966977"> (317) 796 6977</a>
                </p>
                <br />
                <p className="contact-info-option">
                  <FontAwesomeIcon icon={faInstagram} size="lg" /><a href="https://www.instagram.com/amo_makeup/" target="_blank" rel="noreferrer"> @amo_makeup</a>
                </p>
                <br />
                <p className="contact-info-option">
                  <FontAwesomeIcon icon={faEnvelope} size="m" />{" "}
                  <a href="mailto:alicemontana@icloud.com"> alicemontana@icloud.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Spring>
  );
};

export default React.memo(Contact);
