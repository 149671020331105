import React from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export const FocusNavbar = () => {
  const history = useHistory();

  const back = () => {
    history.goBack();
    localStorage.clear();
  };

  return (
    <div className="focused-nav">
      <button className="back-btn" onClick={() => back()}>
        <FontAwesomeIcon className="" icon={faArrowLeft} size="2x" />
      </button>
      <div className="contact-section">
        <Link to="/contact">
          <button className="contact-btn">contact</button>
        </Link>
      </div>
    </div>
  );
};
