import React from "react";
import { Spring } from "react-spring/renderprops";

export const AllWork = () => {
  return (
    <Spring
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
      config={{ delay: 500, duration: 1000 }}
    >
      {(props) => (
        <div style={props}>
          <div className="all-work">
            <div className="gallery-container">
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic.jpeg"
                  alt="hair and makeup by Alice - 1"
                />
                <div className="content">- Hair by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic1.jpeg"
                  alt="hair and makeup by Alice - 2"
                />
                <div className="content">- Highlights by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic2.jpeg"
                  alt="hair and makeup by Alice - 3"
                />
                <div className="content">- Color and cut by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic3.jpeg"
                  alt="hair and makeup by Alice - 4"
                />
                <div className="content">- Makeup by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic4.jpeg"
                  alt="hair and makeup by alice 5"
                />
                <div className="content">- Color by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic5.jpeg"
                  alt="hair and makeup by alice 6"
                />
                <div className="content">- Hair by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic6.jpeg"
                  alt="hair and makeup by alice 7"
                />
                <div className="content">- Hair by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic7.jpeg"
                  alt="hair and makeup by alice 8"
                />
                <div className="content">- Makeup by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic8.jpeg"
                  alt="hair and makeup by alice 9"
                />
                <div className="content">- Hair by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic10.jpeg"
                  alt="hair and makeup by alice 10"
                />
                <div className="content">- Hair by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic11.jpeg"
                  alt="hair and makeup by alice 11"
                />
                <div className="content">- Makeup by Alice</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic12.jpeg"
                  alt="hair and makeup by alice 12"
                />
                <div className="content">- Hair and makeup by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic13.jpeg"
                  alt="hair and makeup by alice 13"
                />
                <div className="content">- Hair and makeup by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic14.png"
                  alt="hair and makeup by Alice - 14"
                />
                <div className="content">- Hair and makeup by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic15.jpeg"
                  alt="hair and makeup by Alice - 15"
                />
                <div className="content">- Makeup by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic16.jpeg"
                  alt="hair and makeup by Alice - 16"
                />
                <div className="content">- Airbrush makeup by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic17.jpeg"
                  alt="hair and makeup by Alice - 17"
                />
                <div className="content">- Makeup by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic18.png"
                  alt="hair and makeup by Alice - 18"
                />
                <div className="content">- Hair and makeup by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic19.jpeg"
                  alt="hair and makeup by Alice - 19"
                />
                <div className="content">- Hair by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic20.jpeg"
                  alt="hair and makeup by Alice - 20"
                />
                <div className="content">- Makeup by Alice -</div>
              </div>
              <div className="work-card">
                <img
                  className="work-image"
                  src="/images/work-pic21.jpeg"
                  alt="hair and makeup by Alice - 21"
                />
                <div className="content">- Hair by Alice -</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Spring>
  );
};
