import React from "react";
import { Link } from "react-router-dom";

export const About = () => {
  return (
    <div id="about" className="about">
      <div className="title">About Me</div>
      <div className="about-container">
        <div className="text">
          <div>
            After attending Indiana University, Alice moved to Los Angeles,
            California in order to pursue her career in beauty. There, she
            graduated from Yamano Beauty College and obtained her aesthetics
            license, which allowed her to work closely with spa clientele. Alice
            has since perfected the art of eyebrow shaping and skin care, and
            has expanded her skillset to include makeup. She has worked with
            some of the best photographers in the industry on photoshoots,
            bridal parties, and advertising shoots since learning and perfecting
            her skills in makeup.
          </div>
          <br />
          <div>
            Her interest in hair styling led her to obtain her license, and she
            now enjoys working as a hair stylist, makeup artist, and
            esthetician. Alice has over 20 years of experience in the beauty
            industry and loves her job. When she’s not working at the salon,
            she’s running a small freelancing business. Check out
            her relaxing studio via the link below!
          </div>
          <br />
          <Link to="/studio">
            <button className="studio-btn">My Studio</button>
          </Link>
        </div>
        <div className="image-section">
          <img src="/images/portfolio-pic1.jpg" alt="alice montana" />
        </div>
      </div>
    </div>
  );
};
