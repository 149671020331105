import React, { useState } from "react";
import { Link } from "react-router-dom";

export const Navbar = () => {
  const [topNav, setTopNav] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setTopNav(true);
    } else {
      setTopNav(false);
    }
  };

  const toggleCheckbox = () => {
    let toggleButton = document.getElementById("nav-check");
    if (toggleButton.checked) {
      toggleButton.checked = false;
    } else {
      toggleButton.checked = true;
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <div className={topNav ? "nav active" : "nav"}>
      <input type="checkbox" id="nav-check" className="nav-check" />
      <div className="nav-header">
        <div className="nav-title">
          <a href="/#">Alice Montana</a>
        </div>
      </div>
      <div className="nav-btn">
        <label htmlFor="nav-check">
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div>

      <div className="nav-links">
        <a id="home-link-mobile" onClick={() => toggleCheckbox()} href="/#">
          home
        </a>
        <a href="#about" onClick={() => toggleCheckbox()}>
          about me
        </a>
        <a href="#work" onClick={() => toggleCheckbox()}>
          work
        </a>
        <a href="#services" onClick={() => toggleCheckbox()}>
          services
        </a>
        <Link
          className="studio-link"
          onClick={() => toggleCheckbox()}
          to="/studio"
        >
          the studio
        </Link>
        <Link
          id="contact-link-mobile"
          onClick={() => toggleCheckbox()}
          to="/contact"
        >
          contact
        </Link>
      </div>
      <div className="contact-section">
        <Link to="/contact">
          <button className="contact-btn">contact</button>
        </Link>
      </div>
    </div>
  );
};
