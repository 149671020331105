import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";


export const Footer = () => {
  const instagramURL = "https://www.instagram.com/amo_makeup/";
  const facebookURL = "https://www.facebook.com/alicemontanaoshier";

  return (
    <div className="footer">
      <div className="social-links">
      <div className="follow-me">Follow Me</div>
        <span>
          <a className="social-link" href={facebookURL} target="_facebook">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
        </span>
        <span>
          <a className="social-link" href={instagramURL} target="_instagram">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
        </span>
      </div>
      <div className="copyright">© Alice Montana 2023</div>
    </div>
  );
};
