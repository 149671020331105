import React from "react";
import { Link } from "react-router-dom";

export const Work = () => {
  return (
    <div id="work" className="work">
      <div className="title">Featured Work</div>
      <div className="work-container">
        <div className="gallery-container">
          <div className="work-card">
            <img
              className="work-image"
              src="/images/work-pic15.jpeg"
              alt="hair and makeup by alice 15"
            ></img>
            <div className="content">- Makeup by Alice -</div>
          </div>
          <div className="work-card">
            <img
              className="work-image"
              src="/images/work-pic18.png"
              alt="hair and makeup by alice 14"
            />
            <div className="content">- Hair and makeup by Alice -</div>
          </div>
          <div className="work-card">
            <img
              className="work-image"
              src="/images/work-pic16.jpeg"
              alt="hair and makeup by alice 2"
            />
            <div className="content">- Airbrush makeup by Alice -</div>
          </div>
        </div>
        <Link to="all-work">
          <button className="browse-all-btn">Browse All</button>
        </Link>
      </div>
    </div>
  );
};
