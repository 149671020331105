import React from "react";

export const Testimonials = () => {
  return (
    <div id="testimonials" className="testimonials">
      <div className="title">Testimonials</div>
      <div className="sub-title">What people say about me</div>
      <div className="container">
        <div className="items">
          <div className="entry">
            <p className="name">Martha Tebbe</p>
            <p className="quote">
              "My 1-hour facial was very relaxing and effective. It does wonders for my complexion and smoothing out fine lines. Alice is great and I have confidence in her expertise."
            </p>
          </div>
          <div className="entry">
            <p className="name">Jeff Miles</p>
            <p className="quote">
              "I recommend Soul Sister Studio, Alice is amazing!"
            </p>
          </div>
          <div className="entry">
            <p className="name">DL Lowry Salon</p>
            <p className="quote">
              "Alice’s calm demeanor immediately puts clients at ease, creating a relaxing and rejuvenating spa experience."
            </p>
          </div>
          <div className="entry">
            <p className="name">Julie Wilkinson</p>
            <p className="quote">
              "A fabulous artist and amazing colorist highly recommend her."
            </p>
          </div>
          <div className="entry">
            <p className="name">Isabelle Miller</p>
            <p className="quote">
              "Alice always does an incredible job communicating with her clients, playing the best music, and executing exactly what her clients wish for! 5/5 star service."
            </p>
          </div>
          <div className="entry">
            <p className="name">Shannon Mattingly</p>
            <p className="quote">
              "Love my hair and makeup!!!! You did such an amazing job!!"
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
