import React from "react";

export const Services = () => {
  return (
    <div id="services" className="services">
      <div className="title">Services I Offer</div>
      <div className="service-container">
        <div className="service-category">
          <div className="title">Hair</div>
          <div className="multi-col-services">
            <ul>
              <li>Cut $55</li>
              <li>Kids cuts $35</li>
              <li>Single color application starting at $80</li>
              <li>Full highlight/lowlight/balayage $125 - $300</li>
            </ul>
            <ul>
              <li>Extensions removal starting at $150</li>
              <li>Extension from $250</li>
              <li>Extension fill in $30 per strand</li>
              <li>Conditioning treatment $35 - $50</li>
            </ul>
            <ul>
              <li>Shampoo and blow dry $45</li>
              <li>Up-do work starting at $75</li>
            </ul>
          </div>
        </div>
        <div className="service-category">
          <div className="title">Makeup</div>
          <div className="multi-col-services">
            <ul>
              <div className="subtitle">Weddings</div>
              <li>
                Bridal makeup (includes airbrush or traditional makeup &
                lashes) $145
              </li>
              <li>Bridesmaids airbrush or traditional makeup $95</li>
              <li>Flower girls (12 & under) $30</li>
              <li>False lash application $20</li>
              <li>On-site cost $50</li>
            </ul>

            <ul id="other">
              <div className="subtitle">
                Other
              </div>
              <li>In studio $75</li>
              <li>On location $100</li>
            </ul>
          </div>
          <br />
          <div className="note">
            * $200 security deposit reserves your wedding date and will be
            deducted from the balance on the day of your wedding
          </div>
        </div>
        <div className="service-category">
          <div className="title">Peels</div>
          <ul>
            <li>Custom chemical peel $50 - $200</li>
            <li>Dermaplaning $45</li>
            <li>Dermaplaning & peel $90</li>
          </ul>
        </div>
        <div className="service-category">
          <div className="title">Lashes</div>
          <ul>
            <li>Lash lifts $45</li>
            <li>Tint lashes $25</li>
            <li>Tint brows $20</li>
          </ul>
        </div>
        <div className="service-category">
          <div className="title">Waxing</div>
          <div className="multi-col-services">
            <ul>
              <li>Brows $20</li>
              <li>Lip/chin $10</li>
              <li>Face $25</li>
              <li>Neck $15</li>
            </ul>
            <ul>
              <li>Underarm $25</li>
              <li>Arms $35</li>
              <li>Legs $40 - $60</li>
              <li>Chest/back $65</li>
            </ul>
            <ul>
              <li>Bikini $30</li>
              <li>French $35</li>
              <li>Brazilian $50</li>
            </ul>
          </div>
        </div>
        <div className="service-category facials">
          <div className="title">Facials</div>
          <ul>
            <li>Hour facial $85 - $120</li>
            <li>Hot stone facials $90</li>
            <li>
              45-minute dry brushing/exfoliating/hot stone body treatment $65
            </li>
          </ul>
        </div>
        <div className="note">* $5 service fee for credit / $3 service fee for Venmo</div>
      </div>
    </div>
  );
};
