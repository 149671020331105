import React from "react";
import { Spring } from "react-spring/renderprops";

export const Landing = () => {
  return (
    <div className="landing">
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ delay: 500, duration: 1000 }}
      >
        {(props) => (
          <div style={props}>
            <img
              className="background-image"
              src="/images/landing.jpg"
              alt="plant and blue background"
            />
          </div>
        )}
      </Spring>
      <div className="text-section">
        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
          config={{ delay: 800, duration: 1000 }}
        >
          {(props) => (
            <div style={props}>
              <div className="title">Alice Montana</div>
            </div>
          )}
        </Spring>
        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
          config={{ delay: 1200, duration: 1000 }}
        >
          {(props) => (
            <div style={props}>
              <div className="sub-title">
                Hair Stylist | Makeup Artist | Esthetician
              </div>
              <a href="#services">
                <button className="see-services">See Services</button>
              </a>
            </div>
          )}
        </Spring>
      </div>
    </div>
  );
};
